@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Mulish&family=Work+Sans:wght@300&display=swap');

h1 {
    text-align: center;
    color: #011936FF;
    font-family: 'Work Sans';
}

.container {
    width: 750px;
    height: 800px;
    margin: auto;
    margin-top: 1.5em;
    outline: solid 1px black;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    background-color: #eeeeee;
}

form {
    font-family: 'Mulish';
}

textarea {
    width: 19em;
    height: 5em;
    resize: none;
    border-radius: 6px;
}

label {
    display: inline-block;
    width: 160px;
    text-align: left;
}

select {
    height: 30px;
    width: 150px;
    border-radius: 6px;
    vertical-align: top;
}

.middleSection {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    margin-top: 1em;
    margin-left: 8em;
    margin-right: 8em;
    margin-bottom: 2em;
}

.error {
    color: red;
    font-weight: bold;
}

.screenshotList {
    margin: 0px;
    font-size: smaller;
}

.fileDeleteButton {
    background: none;
    border: none;
    font-size: smaller;
    cursor: pointer;
    color: red;
}
